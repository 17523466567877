import Page from "../../components/page"
import React, { FC } from "react"
import Challenge from "../../components/challenge"
import ReferencesSection from "../../components/content-sections/references-section"
import { ContactForm } from "../../components/contact-form"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  ExpertDisplay,
  ExpertDisplayStack,
} from "../../components/expert-display"
import {
  BuildingBlock,
  BuildingBlocksGrid,
} from "../../components/building-blocks"
import SEO from "../../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import { OneByOneGrid } from "../../components/layout/grid"

const ValidatingPage: FC<{ location: Location }> = ({ location }) => {
  const { formatMessage } = useIntl()
  const {
    validatingImageData,
    jakobImageData,
    beijaImageData,
  } = useStaticQuery(
    graphql`
      query {
        validatingImageData: file(
          relativePath: { eq: "challenges/validating.png" }
        ) {
          ...DefaultImageOptions
        }
        jakobImageData: file(relativePath: { eq: "members/jakob.jpg" }) {
          ...DefaultImageOptions
        }
        beijaImageData: file(relativePath: { eq: "members/beija.jpg" }) {
          ...DefaultImageOptions
        }
        eugenImageData: file(relativePath: { eq: "members/eugen.jpg" }) {
          ...DefaultImageOptions
        }
      }
    `
  )

  const validatingImage = getImage(validatingImageData)

  return (
    <Page>
      <SEO
        title={formatMessage({ id: `challenge.validating.title` })}
        description={formatMessage({
          id: `challenge.validating.shortDescription`,
        })}
        // ogImage={} TODO
        location={location}
      />

      <Challenge kind={"validating"}>
        <OneByOneGrid>
          <p>
            {formatMessage({ id: `challenge.validating.description.intro` })}
            <br />
            <br />
            {formatMessage({
              id: `challenge.validating.description.main1`,
            })}{" "}
            <b>{formatMessage({ id: `challenges.weHelpMakeADifference` })}</b>{" "}
            {formatMessage({ id: `challenge.validating.description.main2` })}
            <br />
            <br />
            {formatMessage({ id: `challenges.weHelpToEmbed` })}{" "}
            <b>{formatMessage({ id: `challenges.drivingBottomUpChange` })}</b>
          </p>
          <div>
            <GatsbyImage image={validatingImage!} alt="" />
          </div>
        </OneByOneGrid>

        <h2>{formatMessage({ id: `challenges.howWeCanHelp` })}</h2>

        <BuildingBlocksGrid>
          <BuildingBlock kind={"validating"} id={"mockups"} />

          <BuildingBlock kind={"validating"} id={"prototyping"} />

          <BuildingBlock kind={"validating"} id={"cloud"} />

          <BuildingBlock kind={"validating"} id={"serverless"} />

          <BuildingBlock kind={"validating"} id={"cicd"} />

          <BuildingBlock kind={"validating"} id={"lean-product-development"} />
        </BuildingBlocksGrid>

        <h2>{formatMessage({ id: `challenges.references` })}</h2>
        <ReferencesSection ids={["data-driven-experimentation"]} />

        <ContactForm
          title={formatMessage({ id: `challenges.getInTouch` })}
          initialMessage={"Hi Beija, Jakob \n\n"}
        >
          <ExpertDisplayStack>
            <ExpertDisplay
              name={"Beija"}
              title={"Software Engineer"}
              description={"TODO: add short description"}
              imageData={beijaImageData}
            />
            <ExpertDisplay
              name={"Jakob"}
              title={"Solution Architect"}
              description={"TODO: add short description"}
              imageData={jakobImageData}
            />
          </ExpertDisplayStack>
        </ContactForm>
      </Challenge>
    </Page>
  )
}

export default ValidatingPage
